// Transtore Localization app doesn't work well with breakpoint changes

import { debounce } from '../helper/global.js';

const BREAKPOINT = 990;
let isDesktop = window.innerWidth > BREAKPOINT;

function reloadPage() {
  console.log('Reloading page');
  window.location.reload();
}

const debouncedReload = debounce(reloadPage, 250);

function handleResize() {
  const currentWidth = window.innerWidth;
  const currentIsDesktop = currentWidth > BREAKPOINT;

  if (currentIsDesktop !== isDesktop) {
    console.log('Breakpoint transition detected');
    isDesktop = currentIsDesktop;
    debouncedReload();
  }
}

// Use requestAnimationFrame for smoother performance
function onResize() {
  window.requestAnimationFrame(handleResize);
}

// Add event listeners
window.addEventListener('resize', onResize);
window.addEventListener('orientationchange', handleResize);

// Set initial state
window.addEventListener('load', () => {
  setTimeout(() => {
    isDesktop = window.innerWidth > BREAKPOINT;
    console.log('Initial state set:', isDesktop ? 'desktop' : 'mobile');
  }, 1000); // Wait 1 second after load
});

console.log('Breakpoint reload script loaded');
